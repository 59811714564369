// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/nickchan/workspace/aither-membership-portal/public/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("/Users/nickchan/workspace/aither-membership-portal/public/src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-index-js": () => import("/Users/nickchan/workspace/aither-membership-portal/public/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("/Users/nickchan/workspace/aither-membership-portal/public/src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-failed-index-js": () => import("/Users/nickchan/workspace/aither-membership-portal/public/src/pages/reset/failed/index.js" /* webpackChunkName: "component---src-pages-reset-failed-index-js" */),
  "component---src-pages-reset-index-js": () => import("/Users/nickchan/workspace/aither-membership-portal/public/src/pages/reset/index.js" /* webpackChunkName: "component---src-pages-reset-index-js" */),
  "component---src-pages-reset-success-index-js": () => import("/Users/nickchan/workspace/aither-membership-portal/public/src/pages/reset/success/index.js" /* webpackChunkName: "component---src-pages-reset-success-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/nickchan/workspace/aither-membership-portal/public/.cache/data.json")

